<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col col="12" sm="12" md="12">
          <h2>Personal Information</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="LastName"
            :rules="[vv.init(this.LastName), vv.required(), vv.maxlen(45)]"
            label="Last Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="Firstname"
            :rules="[vv.init(this.Firstname), vv.required(), vv.maxlen(45)]"
            label="First Name"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MiddleName"
            :rules="[vv.init(this.MiddleName), vv.required(), vv.maxlen(45)]"
            label="Middle Name"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="NameExt"
            label="Name Ext"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="DOB"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="DOB"
                label="Date of Birth"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="DOB" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(DOB)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="POB"
            :rules="[vv.init(this.POB), vv.required(), vv.maxlen(255)]"
            label="Place of Birth"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="Sex"
            :items="items"
            :rules="[vv.init(this.DOB), vv.requiredSelect()]"
            label="Sex"
            required
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="CivilStatus"
            :items="cs"
            :rules="[vv.init(this.CivilStatus), vv.requiredSelect()]"
            label="Civil Status"
            required
            outlined
            dense
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="Height"
            :rules="[vv.init(this.Height), vv.required(), vv.maxlen(45)]"
            label="Height"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="Weight"
            :rules="[vv.init(this.Weight), vv.required(), vv.maxlen(45)]"
            label="Weight"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="BloodType"
            :rules="[vv.init(this.BloodType), vv.required(), vv.maxlen(45)]"
            label="Blood Type"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="GSIS"
            label="GSIS No."
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PagIbig"
            label="Pag-Ibig No."
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PhilHealth"
            label="PhilHealth No."
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="SSS"
            label="SSS No."
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="TIN"
            label="TIN No."
            outlined
            dense
          ></v-text-field>
        </v-col>

       
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="TelephoneNo"
            label="Telephone No."
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MobileNo"
            :rules="[vv.init(this.MobileNo), vv.required(), vv.maxlen(45)]"
            label="Mobile No."
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="Email"
            :rules="[vv.init(this.Email), vv.valid_email()]"
            label="Email"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col col="12" sm="12" md="12">
          <h2>Citizenship</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="FD"
            :items="fdopt"
            :rules="[vv.init(this.FD), vv.requiredSelect()]"
            label="Filipino or Dual Citizenship"
            required
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="BN"
            :items="bnopt"
            :rules="[vv.init(this.BN), vv.requiredSelect()]"
            label="By Birth or By naturalization"
            required
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            v-model="Country"
            :items="Countryopt"
            :filter="customFilter"
            item-text="name"
            label="If holder of  dual citizenship, Pls. indicate country:"
            outlined
            dense
          ></v-autocomplete>
        </v-col>

        <v-col col="12" sm="12" md="12">
          <h2>Residential Address</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="RHouseNo"
            :rules="[vv.init(this.RHouseNo), vv.required(), vv.maxlen(45)]"
            label="House/Block/Lot No."
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="RStreet"
            label="Street"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="RVillage"
            :rules="[vv.init(this.RVillage), vv.required(), vv.maxlen(45)]"
            label="Subdivision/Village"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="RBrgy"
            :rules="[vv.init(this.RBrgy), vv.required(), vv.maxlen(45)]"
            label="Barangay"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="RCity"
            :rules="[vv.init(this.RCity), vv.required(), vv.maxlen(45)]"
            label="City/Municipality"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="RProvince"
            :rules="[vv.init(this.RProvince), vv.required(), vv.maxlen(45)]"
            label="Province"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="RZip"
            :rules="[vv.init(this.RZip), vv.required(), vv.maxlen(45)]"
            label="ZIP CODE"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col col="12" sm="12" md="12">
          <h2>Permanent Address</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PHouseNo"
            :rules="[vv.init(this.PHouseNo), vv.required(), vv.maxlen(45)]"
            label="House/Block/Lot No."
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PStreet"
            label="Street"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PVillage"
            :rules="[vv.init(this.PVillage), vv.required(), vv.maxlen(45)]"
            label="Subdivision/Village"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PBrgy"
            :rules="[vv.init(this.PBrgy), vv.required(), vv.maxlen(45)]"
            label="Barangay"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PCity"
            :rules="[vv.init(this.PCity), vv.required(), vv.maxlen(45)]"
            label="City/Municipality"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PProvince"
            :rules="[vv.init(this.PProvince), vv.required(), vv.maxlen(45)]"
            label="Province"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PZip"
            :rules="[vv.init(this.PZip), vv.required(), vv.maxlen(45)]"
            label="ZIP CODE"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn
            :disabled="!valid"
            class="mr-4"
            @click="savechanges"
            color="success"
            dense
            >Save Changes</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Your changes have been successfully saved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import vldn from "@/js/validation";
export default {
  name: "Personal",
  data: () => ({
    snackbar: false,
    timeout: 2000,
    valid: false,
    modal: false,
    items: ["Male", "Female"],
    cs: ["Single", "Married", "Widowed", "Separated", "Divorced"],
    fdopt: ["Filipino", "Dual Citizenship"],
    bnopt: ["By Birth", "By naturalization"],
    Countryopt: [],
    LastName: "",
    Firstname: "",
    MiddleName: "",
    NameExt: "",
    DOB: "",
    POB: "",
    Sex: "",
    CivilStatus: "",
    Height: "",
    Weight: "",
    BloodType: "",
    GSIS: "",
    PagIbig: "",
    PhilHealth: "",
    SSS: "",
    TIN: "",
    TelephoneNo: "",
    MobileNo: "",
    Email: "",
    FD: "",
    BN: "",
    Country: "",
    RHouseNo: "",
    RStreet: "",
    RVillage: "",
    RBrgy: "",
    RCity: "",
    RProvince: "",
    RZip: "",
    PHouseNo: "",
    PStreet: "",
    PVillage: "",
    PBrgy: "",
    PCity: "",
    PProvince: "",
    PZip: "",
    vv: vldn.methods,
  }),
  componets: {
    vldn,
  },
  mounted() {
    this.$api.methods.init();
    this.getPerson();
    this.getAddress();

    
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.abbr.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    getAddress() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/addresses",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.Countryopt = response.data.countries;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    getPerson() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/person",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.LastName = response.data[0][0].LastName;
            this.Firstname = response.data[0][0].Firstname;
            this.MiddleName = response.data[0][0].MiddleName;
            this.NameExt = response.data[0][0].NameExt;
            this.DOB = response.data[0][0].DOB;
            this.POB = response.data[0][0].POB;
            this.Sex = response.data[0][0].Sex;
            this.CivilStatus = response.data[0][0].CivilStatus;
            this.Height = response.data[0][0].Height;
            this.Weight = response.data[0][0].Weight;
            this.BloodType = response.data[0][0].BloodType;
            this.GSIS = response.data[0][0].GSIS;
            this.PagIbig = response.data[0][0].PagIbig;
            this.PhilHealth = response.data[0][0].PhilHealth;
            this.SSS = response.data[0][0].SSS;
            this.TIN = response.data[0][0].TIN;
            this.TelephoneNo = response.data[0][0].TelephoneNo;
            this.MobileNo = response.data[0][0].MobileNo;
            this.Email = response.data[0][0].Email;
            if (response.data[1].length > 0) {
              this.RHouseNo = response.data[1][0].RHouseNo;
              this.RStreet = response.data[1][0].RStreet;
              this.RVillage = response.data[1][0].RVillage;
              this.RBrgy = response.data[1][0].RBrgy;
              this.RCity = response.data[1][0].RCity;
              this.RProvince = response.data[1][0].RProvince;
              this.RZip = response.data[1][0].RZip;
              this.PHouseNo = response.data[1][0].PHouseNo;
              this.PStreet = response.data[1][0].PStreet;
              this.PVillage = response.data[1][0].PVillage;
              this.PBrgy = response.data[1][0].PBrgy;
              this.PCity = response.data[1][0].PCity;
              this.PProvince = response.data[1][0].PProvince;
              this.PZip = response.data[1][0].PZip;
            }

            if (response.data[2].length > 0) {
              this.FD = response.data[2][0].FD;
              this.BN = response.data[2][0].BN;
              this.Country = response.data[2][0].Country;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "pds/updateperson",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            LastName: this.LastName,
            Firstname: this.Firstname,
            MiddleName: this.MiddleName,
            NameExt: this.NameExt,
            DOB: this.DOB,
            POB: this.POB,
            Sex: this.Sex,
            CivilStatus: this.CivilStatus,
            Height: this.Height,
            Weight: this.Weight,
            BloodType: this.BloodType,
            GSIS: this.GSIS,
            PagIbig: this.PagIbig,
            PhilHealth: this.PhilHealth,
            SSS: this.SSS,
            TIN: this.TIN,
            TelephoneNo: this.TelephoneNo,
            MobileNo: this.MobileNo,
            Email: this.Email,
            FD: this.FD,
            BN: this.BN,
            Country: this.Country,
            RHouseNo: this.RHouseNo,
            RStreet: this.RStreet,
            RVillage: this.RVillage,
            RBrgy: this.RBrgy,
            RCity: this.RCity,
            RProvince: this.RProvince,
            RZip: this.RZip,
            PHouseNo: this.PHouseNo,
            PStreet: this.PStreet,
            PVillage: this.PVillage,
            PBrgy: this.PBrgy,
            PCity: this.PCity,
            PProvince: this.PProvince,
            PZip: this.PZip,
          },
        })
          .then((response) => {
            if (response) {
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
  },
};
</script>
<style>
.container {
  max-width: 80%;
}
</style>
